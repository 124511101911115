import "./Button.css";
import { useTranslation } from 'react-i18next';

const Button = ({
   onClick,
   inverseColors = false,
   text = 'Book a call',
   className = "",
}) => {
    const { t } = useTranslation();
    return (
        <button onClick={onClick} className={
            `button ${inverseColors ? "black-background" : ""} ${className}`
        }>
            {t(text)}
        </button>
    )
}

export default Button;
