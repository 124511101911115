import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Button from "../Button/Button";
import CookieIcon from "../../assets/img/cookie_icon.png";
import './CookiePopup.css';

const CookiePopup = ({ setIsCookieShown }) => {
    const navigate = useNavigate();

    const { t, i18n } = useTranslation();

    const handleAcceptCookies = () => {
        document.cookie = 'is_accepted=accepted; path=/; max-age=2592000';

        setIsCookieShown(false);
    };

    return (
        <div className="cookie_popup">
            <img className='mr-20px' src={CookieIcon} alt=""/>
            <span className='mr-20px cookie_descr'>
                {t('By using our site, you agree to our ')}
                <span className='cookie_link' onClick={() => (navigate('/cookie-policy'))}>{t('Cookie Policy')}</span>
            </span>
            <Button
                inverseColors
                onClick={handleAcceptCookies}
                text={t('Accept')}
            />
        </div>
    )
}

export default CookiePopup;
