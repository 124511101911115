import Footer from "./Footer/Footer";
import {cloneElement, useState} from "react";

const Layout = ({children}) => {
    const [isDarkTheme, setIsDarkTheme] = useState(true);
    const [showModal, setShowModal] = useState(false);

    const toggleTheme = (isDarkTheme) => {
        setIsDarkTheme(!isDarkTheme);
    };

    return (
        <div className="layout">
            {cloneElement(children, { isDarkTheme, toggleTheme, showModal, setShowModal })}
            <Footer/>
        </div>
    )
}

export default Layout;
