import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {infoData} from "./HomeBlocksData/infoData";

import ArrowBlack from "../../assets/img/icons/arrow_down_black.svg";
import ArrowWhite from "../../assets/img/icons/arrow_down_white.svg";
import {useTranslation} from "react-i18next";
import Header from "../../Layout/Header/Header";
import Button from "../../components/Button/Button";
import InfoBlock from "../../components/InfoBlock/InfoBlock";
import {projectsData} from "./HomeBlocksData/projectsData";
import ProjectBlock from "../../components/ProjectBlock/ProjectBlock";
import Form from "../../components/Form/Form";
import CookiePopup from "../../components/CookiePopup/CookiePopup";
import "./Home.css";

const Home = ({
    isDarkTheme,
    toggleTheme,
    showModal,
    setShowModal
}) => {
    const [isCookieShown, setIsCookieShown] = useState(false);

    const { t } = useTranslation();

    useEffect(() => {
        const cookieName = 'is_accepted=accepted';
        const hasCookie = document.cookie.split(';').some((item) => item.trim().startsWith(`${cookieName}`));

        if (!hasCookie) {
            setTimeout(() => {
                setIsCookieShown(true);
            }, 2000)
        }
    }, []);

    useEffect(()=> {
        if (window.location.hash === "#contact-form"){
            const element = document.getElementById('contact');
            if (element){
                element.scrollIntoView({behavior: 'smooth'});
            }
        }
    }, []);

    const location = useLocation();

    const hash = location.hash;

    useEffect(() => {
        if (hash === '#contact') {
            const element = document.getElementById('contact');
            element.scrollIntoView({behavior: 'smooth'});
        }
    }, [hash]);

    const scrollByClick = (id) => {
        const element = document.getElementById(id);

        element.scrollIntoView({behavior: 'smooth'});
    }

    return (
        <div className='home'>
            {showModal && <div className='blurred'/>}
            <div className={!isDarkTheme ? "hero" : "hero-white"}>
                <div className="content">
                    <Header
                        showModal={showModal}
                        toggleTheme={toggleTheme}
                        setShowModal={setShowModal}
                        inverseColors={isDarkTheme}
                    />
                    <div className="hero-title-block">
                        <h1 className={!isDarkTheme ? "hero-title" : "hero-black"}>
                        <span className="hero-title-wrapper">
                            {t('We offer')}
                        </span>
                            <span className="highlight">
                            {t('full development cycle')}
                        </span>
                            <span className="hero-title-wrapper">
                            {t('from design to testing')}
                        </span>
                        </h1>
                        <p className={isDarkTheme ? "hero-descr" : "hero-descr-white"}>
                            {t('Or we are ready to join your project at any stage of development')}
                        </p>
                        <div className="hero-footer">
                            <Button
                                className="hero-footer-btn"
                                text="Build my solution"
                                inverseColors={isDarkTheme}
                                onClick={() => scrollByClick('contact')}
                            />
                            <span
                                className={`${isDarkTheme ? "hero-footer-base" : "hero-footer-white"} hero-footer-wrap`}
                                onClick={() => scrollByClick('information')}
                            >
                                <span className="hero-footer-descr">{t('Learn more')}</span>
                                <img src={isDarkTheme ? ArrowBlack : ArrowWhite} alt=''/>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div
                id="information"
                className={`${!isDarkTheme && 'info-section-black'} info-section`}
            >
                <div className='content'>
                    <InfoBlock
                        sup={5}
                        title={infoData[0].title}
                        className='info-block'
                        hasPicture
                        isDarkTheme={isDarkTheme}
                        description={infoData[0].description}
                        subDescription={infoData[0].sub_description}
                    />
                    <div className='info-wrapper'>
                        {infoData?.map(({sup, title, description, sub_description}) => (
                            <InfoBlock
                                sup={sup}
                                title={title}
                                isDarkTheme={isDarkTheme}
                                description={description}
                                subDescription={sub_description}
                            />
                        ))}
                    </div>
                    <div className={`${!isDarkTheme && 'info-contact-black'} info-contact`}>
                        <div>
                            <h3 className={`${!isDarkTheme && 'info-contact-header-white'} info-contact-header`}>
                                {t('An unconventional task?')}
                            </h3>
                            <span className={`${!isDarkTheme && 'info-contact-descr-white'} info-contact-descr`}>
                                {t('Contact us. We will look at your task and propose a solution')}
                            </span>
                        </div>
                        <div className="info-contact-btn">
                            <Button
                                inverseColors={isDarkTheme}
                                text={t('Contact us')}
                                onClick={() => scrollByClick('contact')}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div id='projects' className={`projects-block ${!isDarkTheme && 'projects-block-dark'}`}>
                <div className='content'>
                    <h2 className={`projects-header ${!isDarkTheme && 'projects-header-dark'}`}>
                        {t('Projects')}
                    </h2>
                    {projectsData?.map(({sup, tags, title, description, hasFeatures, hasBenefits}) => (
                        <ProjectBlock
                            sup={sup}
                            tags={tags}
                            title={title}
                            isDarkTheme={isDarkTheme}
                            description={description}
                            hasFeatures={hasFeatures}
                            hasBenefits={hasBenefits}
                        />
                    ))}
                </div>
            </div>

            <div id="contact" className={`contact-wrapper ${!isDarkTheme && 'contact-wrapper-dark'}`}>
                <div className="content contact-block">
                    <div className="contact-card-wrapper">
                        <h2 className='contact-card-header'>
                            {t('Contact us')}
                        </h2>
                        <span className='contact-card-descr'>
                            {t('Leave your contact details and we will get back to you as soon as possible')}
                        </span>
                    </div>
                    <div className='contact-form-wrapper'>
                        <Form inverseColors={isDarkTheme}/>
                    </div>
                </div>
            </div>
            <div className={`cookie-wrap ${!isCookieShown && 'cookie-wrap-hidden'} `}>
                <CookiePopup setIsCookieShown={setIsCookieShown}/>
            </div>
            <div className='header-mobile'>
                <Header
                    showModal={showModal}
                    toggleTheme={toggleTheme}
                    setShowModal={setShowModal}
                    inverseColors={isDarkTheme}
                    isMobileHeader
                />
            </div>
        </div>
    )
}

export default Home;
