import {useEffect} from "react";
import {useTranslation} from 'react-i18next';
import closeIcon from '../../assets/img/icons/close_icon.svg';
import Form from '../Form/Form';
import './ModalContactUs.css';

const ModalContactUs = ({ showModal, setShowModal, inverseColors }) => {
    const {t, i18n} = useTranslation();

    const closeModal = () => setShowModal(false);

    useEffect(() => {
        if (showModal) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }

        // Очистка эффекта при размонтировании компонента
        return () => {
            document.body.classList.remove('no-scroll');
        };
    }, [showModal]);

    return (
        <div className={`${showModal && 'active'} ${!inverseColors && 'modal-black'} modal-wrapper`}>
            <div className='modal-header-wrapper'>
                <h3
                    id='contact-form'
                    className={`${!inverseColors && 'modal-header-white'} modal-header`}
                >
                    {t('Contact us')}
                </h3>
                <img
                    className='modal-header-icon'
                    src={closeIcon}
                    onClick={closeModal}
                />
            </div>
            <p className={`${!inverseColors && 'modal-descr-white'} modal-descr`}>
                {t('Leave your contact details and we will get back to you as soon as possible')}
            </p>
            <Form inverseColors={inverseColors} />
        </div>
    )
}

export default ModalContactUs;
