import {useTranslation} from "react-i18next";
import './ProjectBlock.css';
import {benefitsData, featuresData} from "../../pages/Home/HomeBlocksData/projectsData";
import ProjectFeatureBlock from "../ProjectFeatureBlock/ProjectFeatureBlock";

const ProjectBlock = ({
   sup,
   tags,
   title,
   className = '',
   isDarkTheme,
   description,
   hasFeatures,
   hasBenefits,
}) => {
    const { t } = useTranslation();

    const renderTags = tags.map((tag) => (
        <span className='project-tag'>
            {t(tag)}
        </span>
    ));

    const renderFeaturesBlocks = featuresData?.map(({ sup, title, description }) => (
        <ProjectFeatureBlock
            sup={sup}
            title={title}
            isDarkTheme={isDarkTheme}
            description={description}
        />
    ))

    const renderBenefitsBlocks = benefitsData?.map(({ sup, title, description }) => (
        <ProjectFeatureBlock
            sup={sup}
            title={title}
            isDarkTheme={isDarkTheme}
            description={description}
        />
    ))

    return (
        <div key={sup} className={`${className} ${!isDarkTheme && 'project-block-dark'} project-block`}>
            <div className='project-wrap'>
                <div className='project-header-block'>
                    <h3 className={`project-header ${!isDarkTheme && 'project-header-dark'} `}>
                        {t(title)}
                    </h3>
                    <div className='project-tag-block'>
                        {renderTags}
                    </div>
                </div>
                <p className={`project-descr ${!isDarkTheme && 'project-descr-dark'}`}>
                    {t(description)}
                </p>
            </div>
            {hasFeatures && (
                <div className='project-sub-wrap'>
                    <h4 className={`${!isDarkTheme && 'project-sub-head-dark'} project-sub-head`}>{t('Key Features')}:</h4>
                    <div className='project-sub-descr'>
                        {renderFeaturesBlocks}
                    </div>
                </div>
            )}
            <div>
                {hasBenefits && (
                    <>
                        <h4 className={`${!isDarkTheme && 'project-sub-head-dark'} project-sub-head`}>{t('Benefits')}:</h4>
                        <div className='project-sub-descr'>
                            {renderBenefitsBlocks}
                        </div>
                    </>
                )}
            </div>
        </div>

    );
};

export default ProjectBlock;
