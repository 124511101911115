export const projectsData = [
    {
        sup: 1,
        title: 'Virtua Gym system & the Holded accounting system',
        description: 'Our team recently developed an adapter between the Virtua Gym system and the Holded accounting system. This adapter seamlessly forwards sales and client information from the VirtuaGym to Holded, ensuring that all data is accurate and up-to-date. This solution allows for streamlined data management and improved financial reporting capabilities for our client. By implementing this adapter, our client has been able to save time and resources previously spent on manual data transfer.',
        tags: ["Finance", "Accounting", "Adapters"],
    },
    {
        sup: 2,
        title: 'E‑signature systems',
        description: 'Your team has experience in integrating with electronic signature systems to provide services for exchanging documents signed with qualified encrypted signatures. This integration allows for secure and legally binding digital document signing, streamlining the document exchange process and increasing efficiency for our clients. The integration ensures compliance with relevant laws and regulations, making it ideal for industries such as finance, healthcare and legal.',
        tags: ["Signatures", "Adapters"],
    },
    {
        sup: 3,
        title: 'Billing system',
        description: 'A client which is rendering IT services had its own billing service and was looking for a solution to integrate bank account information with the billing system. He did not want to use any SaaS solution because data protection was critical. We developed an on-premise scalable solution, which supports both manual and automated data input and integrated it with a given clients billing systems.',
        tags: ["Finance", "Adapters"],
    },
    {
        sup: 4,
        title: 'Clients personal space',
        description: 'A client had a CRM system, where he had his leads and clients registered, and a separate website. We implemented a clients personal space where they could login and see their data on visits and performance streamlined from CRM system.',
        tags: ["CRM", "Data", "Adapters"],
    },
    {
        sup: 5,
        title: 'Streamlining Sales Data Management and Incentive Programs',
        description: 'We have developed a cutting-edge back-office system designed to revolutionize how businesses handle sales data and incentivize their sales teams. Our platform seamlessly collects and organizes sales reports from sales representatives and distributors, automates bonus point calculations, and manages engaging raffle programs-all through an intuitive admin panel.',
        tags: ["Data", "Adapters"],
        hasFeatures: true,
        hasBenefits: true,
    },
];

export const featuresData = [
    {
        sup: 1,
        title: 'Automated Data Collection & Organization',
        description: 'Effortlessly gather sales data from various sources. Our system consolidates sales reports from representatives and distributors into a centralized database, eliminating manual entry and reducing errors.',
    },
    {
        sup: 2,
        title: 'Dynamic Bonus Point Calculation',
        description: 'Customize bonus point rules and settings via the admin panel. The system automatically calculates points based on these parameters, ensuring fair and motivating rewards aligned with your business goals.',
    },
    {
        sup: 3,
        title: 'Engaging Raffle System',
        description: 'On a periodic basis (e.g., monthly), the system conducts a raffle to award promotional gifts to your sales representatives. Utilizing a sophisticated algorithm with random generators, the raffle is weighted according to the bonus points earned, enhancing engagement and excitement among your team.',
    },
    {
        sup: 4,
        title: 'Intuitive Admin Panel',
        description: 'Gain full control over settings, rules, and parameters. Easily adjust bonus calculations, manage raffle criteria, and monitor sales performance through a user-friendly interface.',
    },
    {
        sup: 5,
        title: 'Comprehensive Reporting & Analytics',
        description: 'Comprehensive Reporting & Analytics: Access detailed reports and analytics to track sales trends, bonus distributions, and the effectiveness of incentive programs, enabling informed decision-making.',
    },
];

export const benefitsData = [
    {
        sup: 1,
        title: 'Boosted Team Motivation',
        description: 'Implement a transparent and rewarding system that recognizes and incentivizes top performers, fostering a competitive yet collaborative environment.',
    },
    {
        sup: 2,
        title: 'Increased Efficiency',
        description: 'Reduce administrative workload with automated processes, allowing your team to focus on strategic tasks rather than manual data management.',
    },
    {
        sup: 3,
        title: 'Customization & Scalability',
        description: 'Tailor the system to fit your unique business needs with the flexibility to scale as your company grows.',
    },
    {
        sup: 4,
        title: 'Fair & Transparent Rewards',
        description: 'Ensure all sales representatives have opportunities to be rewarded, with higher achievers receiving greater chances in raffles, promoting fairness and enthusiasm.',
    },
];
