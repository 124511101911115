import {useTranslation} from "react-i18next";
import Header from "../../Layout/Header/Header";
import CookieCard from "./components/CookieCard/CookieCard";
import {cookieData} from "./cookieData";
import "./Cookie.css"

const Cookie = ({
    showModal,
    toggleTheme,
    setShowModal,
    isDarkTheme
}) => {
    const { t } = useTranslation();

    const renderCookieCard = cookieData.map(({
        sup,
        list,
        title,
        subDescr,
        isDefList,
        thirdDescr,
        description,
    }) => (
        <CookieCard
            sup={sup}
            title={title}
            list={list}
            subDescr={subDescr}
            isDefList={isDefList}
            description={description}
            thirdDescr={thirdDescr}
            isDarkTheme={isDarkTheme}
        />
    ));

    return (
        <div className={`cookie-wrapper ${isDarkTheme && 'cookie-wrapper-light'}`}>
            {showModal && <div className='blurred' />}
            <div className='content'>
                <Header
                    showModal={showModal}
                    toggleTheme={toggleTheme}
                    setShowModal={setShowModal}
                    inverseColors={isDarkTheme}
                />
            </div>
            <div className='content cookie-card-block'>
                <h1 className={`cookie-header ${!isDarkTheme && 'cookie-header-light'}`}>
                    {t('Cookie Policy')}
                </h1>
                <span className='cookie-update'>
                    {t('Updated at ')}2023-01-21
                </span>
                {renderCookieCard}
            </div>
        </div>
    )
}

export default Cookie;
