export const infoData = [
    {
        sup: 1,
        title: 'Architecture',
        description: 'You have a super 100% fantastic idea of a product you want, but do not know what you need to implement it? Should it be a standalone program or a cloud solution?',
        sub_description: 'We will listen to your need and offer your most suitable solution from which blocks it should be build. Message us to get an architectural and product development advice.',
    },
    {
        sup: 2,
        title: 'Development',
        description: 'You have a software your company works on, but the deadline is near and there is still much to be done?',
        sub_description: 'Lets see how the scope can be optimized and which resources you need to finish your tasks in time. Message us to get development advice.',
    },
    {
        sup: 3,
        title: 'New technologies',
        description: 'You have a running business and you see how you can get an edge over your competitors, but you need an instrument for it.',
        sub_description: 'We can help you get a leverage with new technology. Message us to get competitive advantage with technological solutions.',
    },
    {
        sup: 4,
        title: 'Integration',
        description: 'You have several systems which need to exchange information and work together?',
        sub_description: 'We can investigate and offer options how to deal with it, including adapters, API bridges, etc. Message us to get an intergration advice.',
    },
];
