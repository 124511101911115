import {useTranslation} from "react-i18next";
import './ProjectFeatureBlock.css';

const ProjectFeatureBlock = ({
   sup,
   title,
   className = '',
   isDarkTheme,
   description,
}) => {
    const { t } = useTranslation();

    return (
        <div key={sup} className={`${className} ${!isDarkTheme && 'feature-block-dark'} feature-block`}>
            <h5 className={`${className} ${!isDarkTheme && 'feature-header-dark'} feature-header`}>
                {t(title)}
            </h5>
            <p className={`${className} ${!isDarkTheme && 'feature-descr-dark'} feature-descr`}>
                {t(description)}
            </p>
        </div>
    );
};

export default ProjectFeatureBlock;
