import {useState} from "react";
import {useTranslation} from "react-i18next";
import "./Form.css";


const Form = ({ inverseColors }) => {
    const [isAgreed, setIsAgreed] = useState(false);
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const { t } = useTranslation();

    const idSubmitBtnDisabled = !isAgreed || !email || !message;

    const handleSubmit = (e) => {
        e.preventDefault();
        async function send () {
            await fetch('https://itdrs.tech/sendMessage/',{
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    "Content-type": "application/json"
                },
                body: JSON.stringify(
                    {from: email, message: message, agree: isAgreed}
                )
            })
        }
        send();
        // setShowModal(true);
        // setTimeout(() => {
        //     setShowModal(false);
        // }, 4000);
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className='form-item-wrapper'>
                <span className={`${!inverseColors && 'form-item-header-white'} form-item-header`}>{t('E-mail')}</span>
                <input
                    className={`${!inverseColors && 'form-item-input-black'} form-item-input`}
                    required
                    type='email'
                    placeholder={t('Write your e-mail address')}
                    onChange={e => setEmail(e.target.value)}
                />
            </div>
            <div className='form-item-wrapper'>
                <span className={`${!inverseColors && 'form-item-header-white'} form-item-header`}>{t('Comment')}</span>
                <textarea
                    rows={5}
                    className={`${!inverseColors && 'form-item-input-black'} form-item-input`}
                    required
                    placeholder={t('Write your message')}
                    onChange={e => setMessage(e.target.value)}
                />
            </div>
            <div className='form-item-wrapper form-item-block'>
                <label className="custom-checkbox">
                    <input
                        type='checkbox'
                        className={`${!inverseColors && 'form-item-checkbox-black'} form-item-checkbox`}
                        checked={isAgreed}
                        onChange={(e) => setIsAgreed(e.target.checked)}
                    />
                    <span className="checkmark"></span>
                </label>
                <span
                    className={`
                        ${!inverseColors && 'form-item-header-white'} 
                        form-item-header 
                        form-item-checkbox-descr
                        ${isAgreed && 'form-item-checkbox-active'}`
                    }
                >
                    {t('I agree to transmit data for feedback')}
                </span>
            </div>
            <button
                className={`${!inverseColors && 'form-item-btn-white'} form-item-btn`}
                disabled={idSubmitBtnDisabled}
            >
                {t('Send')}
            </button>
        </form>
    )
}

export default Form;
