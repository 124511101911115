import Architecture from "../../assets/img/architecture.png";
import './InfoBlock.css';
import {useTranslation} from "react-i18next";

const InfoBlock = ({
   sup,
   title,
   className = '',
   hasPicture = false,
   isDarkTheme,
   description,
   subDescription,
}) => {
    const { t } = useTranslation();

    return sup !== 1 && (
        <>
            <div key={sup} className={`info-item ${className} ${!isDarkTheme && 'info-item-black'}`}>
                <div>
                    <h3 className='info-header'>
                        {t(title)}
                    </h3>
                    <p className={`info-descr ${!isDarkTheme && 'info-descr-black'}`}>
                        {t(description)}
                    </p>
                    <p className='info-sub-descr'>
                        {t(subDescription)}
                    </p>
                </div>
                {hasPicture && <img className='info-img' src={Architecture} alt="Изображение архитектуры"/>}
            </div>
        </>

    );
};

export default InfoBlock;
