import {Link, useLocation, useNavigate} from "react-router-dom";
import {useEffect} from "react";

import './Footer.css';
import logoFooter from "../../assets/img/logo_white.svg";
import {useTranslation} from "react-i18next";

const Footer = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const { t, i18n } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    const scrollByClick = (id) => {
        if (location.pathname === "/cookie-policy") {
            navigate('/');
        } else {
            const element = document.getElementById(id);

            element.scrollIntoView({behavior: 'smooth'});
        }
    }

    return (
        <footer className="footer">
            <div className="content footer-wrap">
                <div className='footer-logo-wrap'>
                    <Link to="/">
                        <img className="footer-logo" src={logoFooter} alt="IT & Design"/>
                    </Link>
                    <span className='footer-logo-descr'>
                        © {t('2022-2024 All rights reserved')}
                    </span>
                </div>
                <div className='footer-clients-wrap'>
                    <div className='footer-clients'>
                        <p className='footer-title'>{t('Clients')}</p>
                        <p className='footer-descr footer-client' onClick={() => scrollByClick('information')}>{t('Services')}</p>
                        <p className='footer-descr footer-client' onClick={() => scrollByClick('projects')}>{t('Projects')}</p>
                        <p className='footer-descr footer-client' onClick={() => scrollByClick('contact')}>{t('Contact us')}</p>
                        <p className='footer-descr footer-client'>
                            <Link to="/cookie-policy">{t('Cookie Policy')}</Link>
                        </p>
                    </div>
                </div>
                <div className='footer-contacts-wrap'>
                    <div className='footer-contacts'>
                        <p className='footer-title'>{t('Contacts')}</p>
                        <p className='footer-descr'>IT and Design RS d.o.o.</p>
                        <p className='footer-descr'>{t('Belgrade')}, Bulevard Mihajla<br/> Pupina 10A</p>
                        <p className='footer-descr'><a href='tel:0629382340'>0 629 38 23 40</a></p>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;
