import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    en: {
        translation: {
            "E-mail": "E-mail",
            "Accept": "Accept",
            "Clients": "Clients",
            "Services": "Services",
            "Comment": "Comment",
            "Projects": "Projects",
            "Book a call": "Book a call",
            "Learn more": "Learn more",
            "We offer": "We offer",
            "Updated at ": "Updated at ",
            "By using our site, you agree to our ": "By using our site, you agree to our ",
            "full development cycle": "full development cycle",
            "from design to testing": "from design to testing",
            "from design to testing or we are ready to join your project at any stage": "from design to testing or we are ready to join your project at any stage",
            "or we are ready to join your project at any stage": "or we are ready to join your project at any stage",
            "Architecture":"Architecture",
            "Our team can provide you with a comprehensive software project architecture development service that will help you achieve your goals.": "Our team can provide you with a comprehensive software project architecture development service that will help you achieve your goals.",
            "Design": "Design",
            "Our team provides comprehensive web design services to create visually stunning, user-friendly websites that align with your business goals and target audience.": "Our team provides comprehensive web design services to create visually stunning, user-friendly websites that align with your business goals and target audience.",
            "Development": "Development",
            "Infrastructure": "Infrastructure",
            "We use VDS or cloud providers like AWS, Google Cloud, Yandex Cloud, and Selectel to develop our&nbsp;solutions.": "We use VDS or cloud providers like AWS, Google Cloud, Yandex Cloud, and Selectel to develop our&nbsp;solutions.",
            "Frontend": "Frontend",
            "We use the latest technologies like Vanilla JS, React, and&nbsp;Server-Side Rendering (SSR) to develop fast, responsive and&nbsp;dynamic frontend solutions.":"We use the latest technologies like Vanilla JS, React, and&nbsp;Server-Side Rendering (SSR) to develop fast, responsive and&nbsp;dynamic frontend solutions.",
            "Backend":"Backend",
            "Whether you need a monolithic or microservices architecture, our team can help you make the right choice for your project.":"Whether you need a monolithic or microservices architecture, our team can help you make the right choice for your project.",
            "Testing": "Testing",
            "Our team performs thorough testing to ensure that the product meets the requirements and works as expected.\n Don't let lack of in-house development expertise hold you back. Let us build your custom software today.": "Our team performs thorough testing to ensure that the product meets the requirements and works as expected.\\n Don\\'t let lack of in-house development expertise hold you back. Let us build your custom software today.",
            "Build my solution": "Build my solution",
            "Message us to get an architectural and product development advice.": "Message us to get an architectural and product development advice.",
            "You have several systems which need to exchange information and work together? We can investigate and offer options how to deal with it, including adapters, API bridges, etc.": "You have several systems which need to exchange information and work together? We can investigate and offer options how to deal with it, including adapters, API bridges, etc.",
            "Message us to get an integration advice.":"Message us to get an integration advice.",
            "You have a software your company works on, but the deadline is near and there is still much to be done? Lets see how the scope can be optimized and which resources you need to finish your tasks in time.": "You have a software your company works on, but the deadline is near and there is still much to be done? Lets see how the scope can be optimized and which resources you need to finish your tasks in time.",
            "Leave your contact details and we will get back to you as soon as possible": "Leave your contact details and we will get back to you as soon as possible",
            "Write your e-mail address": "Write your e-mail address",
            "Write your message": "Write your message",
            "You have a super 100% fantastic idea of a product you want, but do not know what you need to implement it? Should it be a standalone program or a cloud solution?": "You have a super 100% fantastic idea of a product you want, but do not know what you need to implement it? Should it be a standalone program or a cloud solution?",
            "We will listen to your need and offer your most suitable solution from which blocks it should be build. Message us to get an architectural and product development advice.": "We will listen to your need and offer your most suitable solution from which blocks it should be build. Message us to get an architectural and product development advice.",
            "I agree to transmit data for feedback": "I agree to transmit data for feedback",
            "Or we are ready to join your project at any stage of development": "Or we are ready to join your project at any stage of development",
            "You have a software your company works on, but the deadline is near and there is still much to be done?": "You have a software your company works on, but the deadline is near and there is still much to be done?",
            "Lets see how the scope can be optimized and which resources you need to finish your tasks in time. Message us to get development advice.": "Lets see how the scope can be optimized and which resources you need to finish your tasks in time. Message us to get development advice.",
            "You have a running business and you see how you can get an edge over your competitors, but you need an instrument for it.": "You have a running business and you see how you can get an edge over your competitors, but you need an instrument for it.",
            "We can help you get a leverage with new technology. Message us to get competitive advantage with technological solutions.": "We can help you get a leverage with new technology. Message us to get competitive advantage with technological solutions.",
            "You have several systems which need to exchange information and work together?": "You have several systems which need to exchange information and work together?",
            "We can investigate and offer options how to deal with it, including adapters, API bridges, etc. Message us to get an intergration advice.": "We can investigate and offer options how to deal with it, including adapters, API bridges, etc. Message us to get an intergration advice.",
            "An unconventional task?": "An unconventional task?",
            "Contact us. We will look at your task and propose a solution": "Contact us. We will look at your task and propose a solution",
            "Virtua Gym system & the Holded accounting system": "Virtua Gym system & the Holded accounting system",
            "Our team recently developed an adapter between the Virtua Gym system and the Holded accounting system. This adapter seamlessly forwards sales and client information from the VirtuaGym to Holded, ensuring that all data is accurate and up-to-date. This solution allows for streamlined data management and improved financial reporting capabilities for our client. By implementing this adapter, our client has been able to save time and resources previously spent on manual data transfer.": "Our team recently developed an adapter between the Virtua Gym system and the Holded accounting system. This adapter seamlessly forwards sales and client information from the VirtuaGym to Holded, ensuring that all data is accurate and up-to-date. This solution allows for streamlined data management and improved financial reporting capabilities for our client. By implementing this adapter, our client has been able to save time and resources previously spent on manual data transfer.",
            "Finance": "Finance",
            "Accounting": "Accounting",
            "Adapters": "Adapters",
            "Signatures": "Signatures",
            "Data": "Data",
            "Benefits": "Benefits",
            "Key Features": "Key Features",
            "E-signature systems": "E-signature systems",
            "Billing system": "Billing system",
            "Clients personal space": "Clients personal space",
            "Streamlining Sales Data Management and Incentive Programs": "Streamlining Sales Data Management and Incentive Programs",
            "Your team has experience in integrating with electronic signature systems to provide services for exchanging documents signed with qualified encrypted signatures. This integration allows for secure and legally binding digital document signing, streamlining the document exchange process and increasing efficiency for our clients. The integration ensures compliance with relevant laws and regulations, making it ideal for industries such as finance, healthcare and legal.": "Your team has experience in integrating with electronic signature systems to provide services for exchanging documents signed with qualified encrypted signatures. This integration allows for secure and legally binding digital document signing, streamlining the document exchange process and increasing efficiency for our clients. The integration ensures compliance with relevant laws and regulations, making it ideal for industries such as finance, healthcare and legal.",
            "A client which is rendering IT services had its own billing service and was looking for a solution to integrate bank account information with the billing system. He did not want to use any SaaS solution because data protection was critical. We developed an on-premise scalable solution, which supports both manual and automated data input and integrated it with a given clients billing systems.": "A client which is rendering IT services had its own billing service and was looking for a solution to integrate bank account information with the billing system. He did not want to use any SaaS solution because data protection was critical. We developed an on-premise scalable solution, which supports both manual and automated data input and integrated it with a given clients billing systems.",
            "A client had a CRM system, where he had his leads and clients registered, and a separate website. We implemented a clients personal space where they could login and see their data on visits and performance streamlined from CRM system.": "A client had a CRM system, where he had his leads and clients registered, and a separate website. We implemented a clients personal space where they could login and see their data on visits and performance streamlined from CRM system.",
            "We have developed a cutting-edge back-office system designed to revolutionize how businesses handle sales data and incentivize their sales teams. Our platform seamlessly collects and organizes sales reports from sales representatives and distributors, automates bonus point calculations, and manages engaging raffle programs-all through an intuitive admin panel.": "We have developed a cutting-edge back-office system designed to revolutionize how businesses handle sales data and incentivize their sales teams. Our platform seamlessly collects and organizes sales reports from sales representatives and distributors, automates bonus point calculations, and manages engaging raffle programs-all through an intuitive admin panel.",
            "Automated Data Collection & Organization": "Automated Data Collection & Organization",
            "Dynamic Bonus Point Calculation": "Dynamic Bonus Point Calculation",
            "Engaging Raffle System": "Engaging Raffle System",
            "Intuitive Admin Panel": "Intuitive Admin Panel",
            "Comprehensive Reporting & Analytics": "Comprehensive Reporting & Analytics",
            "Boosted Team Motivation": "Boosted Team Motivation",
            "Increased Efficiency": "Increased Efficiency",
            "Customization & Scalability": "Customization & Scalability",
            "Fair & Transparent Rewards": "Fair & Transparent Rewards",
            "Ensure all sales representatives have opportunities to be rewarded, with higher achievers receiving greater chances in raffles, promoting fairness and enthusiasm.": "Ensure all sales representatives have opportunities to be rewarded, with higher achievers receiving greater chances in raffles, promoting fairness and enthusiasm.",
            "Tailor the system to fit your unique business needs with the flexibility to scale as your company grows.": "Tailor the system to fit your unique business needs with the flexibility to scale as your company grows.",
            "Reduce administrative workload with automated processes, allowing your team to focus on strategic tasks rather than manual data management.": "Reduce administrative workload with automated processes, allowing your team to focus on strategic tasks rather than manual data management.",
            "Implement a transparent and rewarding system that recognizes and incentivizes top performers, fostering a competitive yet collaborative environment.": "Implement a transparent and rewarding system that recognizes and incentivizes top performers, fostering a competitive yet collaborative environment.",
            "Comprehensive Reporting & Analytics: Access detailed reports and analytics to track sales trends, bonus distributions, and the effectiveness of incentive programs, enabling informed decision-making.": "Comprehensive Reporting & Analytics: Access detailed reports and analytics to track sales trends, bonus distributions, and the effectiveness of incentive programs, enabling informed decision-making.",
            "Gain full control over settings, rules, and parameters. Easily adjust bonus calculations, manage raffle criteria, and monitor sales performance through a user-friendly interface.": "Gain full control over settings, rules, and parameters. Easily adjust bonus calculations, manage raffle criteria, and monitor sales performance through a user-friendly interface.",
            "On a periodic basis (e.g., monthly), the system conducts a raffle to award promotional gifts to your sales representatives. Utilizing a sophisticated algorithm with random generators, the raffle is weighted according to the bonus points earned, enhancing engagement and excitement among your team.": "On a periodic basis (e.g., monthly), the system conducts a raffle to award promotional gifts to your sales representatives. Utilizing a sophisticated algorithm with random generators, the raffle is weighted according to the bonus points earned, enhancing engagement and excitement among your team.",
            "Customize bonus point rules and settings via the admin panel. The system automatically calculates points based on these parameters, ensuring fair and motivating rewards aligned with your business goals.": "Customize bonus point rules and settings via the admin panel. The system automatically calculates points based on these parameters, ensuring fair and motivating rewards aligned with your business goals.",
            "Effortlessly gather sales data from various sources. Our system consolidates sales reports from representatives and distributors into a centralized database, eliminating manual entry and reducing errors.": "Effortlessly gather sales data from various sources. Our system consolidates sales reports from representatives and distributors into a centralized database, eliminating manual entry and reducing errors.",
            "2024 All rights reserved": "2024 All rights reserved",
            "Definitions And Key Terms": "Definitions And Key Terms",
            "Introduction": "Introduction",
            "What Is  A Cookie?": "What Is  A Cookie?",
            "Why Do We Use Cookies?": "Why Do We Use Cookies?",
            "What Type Of Cookies  Does ITDRS Use?": "What Type Of Cookies  Does ITDRS Use?",
            "Essential Cookies": "Essential Cookies",
            "Performance  And Functionality Cookies": "Performance  And Functionality Cookies",
            "Analytics  And Customization Cookies": "Analytics  And Customization Cookies",
            "Third Party Cookies": "Third Party Cookies",
            "How You Can Manage Cookies?": "How You Can Manage Cookies?",
            "Blocking And Disabling  Cookies And Similar  Technologies": "Blocking And Disabling  Cookies And Similar  Technologies",
            "Changes To Our  Cookie Policy": "Changes To Our  Cookie Policy",
            "Your Consent": "Your Consent",
            "By using our platform, registering an account, or making a purchase, you hereby consent to our Cookie Policy and agree to its terms.": "By using our platform, registering an account, or making a purchase, you hereby consent to our Cookie Policy and agree to its terms.",
            "We may change our Service and policies, and we may need to make changes to this Cookie Policy so that they accurately reflect our Service and policies. Unless otherwise required by law, we will notify you (for example, through our Service) before we make changes to this Cookie Policy and give you an opportunity to review them before they go into effect. Then, if you continue to use the Service, you will be bound by the updated Cookie Policy. If you do not want to agree to this or any updated Cookie Policy, you can delete your account.": "We may change our Service and policies, and we may need to make changes to this Cookie Policy so that they accurately reflect our Service and policies. Unless otherwise required by law, we will notify you (for example, through our Service) before we make changes to this Cookie Policy and give you an opportunity to review them before they go into effect. Then, if you continue to use the Service, you will be bound by the updated Cookie Policy. If you do not want to agree to this or any updated Cookie Policy, you can delete your account.",
            "Wherever you're located you may also set your browser to block cookies and similar technologies, but this action may block our essential cookies and prevent our website from functioning properly, and you may not be able to fully utilize all of its features and services. You should also be aware that you may also lose some saved information (e.g. saved login details, site preferences) if you block cookies on your browser. Different browsers make different controls available to you. Disabling a cookie or category of cookie does not delete the cookie from your browser, you will need to do this yourself from within your browser, you should visit your browser's help menu for more information.": "Wherever you're located you may also set your browser to block cookies and similar technologies, but this action may block our essential cookies and prevent our website from functioning properly, and you may not be able to fully utilize all of its features and services. You should also be aware that you may also lose some saved information (e.g. saved login details, site preferences) if you block cookies on your browser. Different browsers make different controls available to you. Disabling a cookie or category of cookie does not delete the cookie from your browser, you will need to do this yourself from within your browser, you should visit your browser's help menu for more information.",
            "Browser manufacturers provide help pages relating to cookie management in their products.": "Browser manufacturers provide help pages relating to cookie management in their products.",
            "Most browsers allow you to control cookies through their 'settings' preferences. However, if you limit the ability of websites to set cookies, you may worsen your overall user experience, since it will no longer be personalized to you. It may also stop you from saving customized settings like login information. Browser manufacturers provide help pages relating to cookie management in their products.": "Most browsers allow you to control cookies through their 'settings' preferences. However, if you limit the ability of websites to set cookies, you may worsen your overall user experience, since it will no longer be personalized to you. It may also stop you from saving customized settings like login information. Browser manufacturers provide help pages relating to cookie management in their products.",
            "Some cookies that have been set on our platform are not set on a first party basis by ITDRS. The Websites can be embedded with content from third parties to serve advertising. These third party service providers may set their own cookies on your web browser. Third party service providers control many of the performance and functionality, advertising, marketing and analytics cookies described above. We do not control the use of these third party cookies as cookies can only be accessed by the third party that originally set them.": "Some cookies that have been set on our platform are not set on a first party basis by ITDRS. The Websites can be embedded with content from third parties to serve advertising. These third party service providers may set their own cookies on your web browser. Third party service providers control many of the performance and functionality, advertising, marketing and analytics cookies described above. We do not control the use of these third party cookies as cookies can only be accessed by the third party that originally set them.",
            "We use cookies served by Google Analytics to collect limited data directly from end-user browsers to enable us to better understand your use of our platform. Further information on how Google collects and uses this data can be found at: https://www.google.com/policies/privacy/partners/. You can opt-out of all Google supported analytics on our Websites by visiting: https://tools.google.com/dlpage/gaoptout.": "We use cookies served by Google Analytics to collect limited data directly from end-user browsers to enable us to better understand your use of our platform. Further information on how Google collects and uses this data can be found at: https://www.google.com/policies/privacy/partners/. You can opt-out of all Google supported analytics on our Websites by visiting: https://tools.google.com/dlpage/gaoptout.",
            "These cookies collect information that is used to help us understand how our platform is being used or how effective our marketing campaigns are, or to help us customize our platform for you.": "These cookies collect information that is used to help us understand how our platform is being used or how effective our marketing campaigns are, or to help us customize our platform for you.",
            "These cookies are used to enhance the performance and functionality of our platform but are non-essential to their use. However, without these cookies, certain functionality like videos may become unavailable or you would be required to enter your login details every time you visit the platform as we would not be able to remember that you had logged in previously.": "These cookies are used to enhance the performance and functionality of our platform but are non-essential to their use. However, without these cookies, certain functionality like videos may become unavailable or you would be required to enter your login details every time you visit the platform as we would not be able to remember that you had logged in previously.",
            "We use essential cookies to make our platform work. These cookies are strictly necessary to enable core functionality such as security, network management, your cookie preferences and accessibility. Without them you wouldn't be able to use basic services. You may disable these by changing your browser settings, but this may affect how the Websites function.": "We use essential cookies to make our platform work. These cookies are strictly necessary to enable core functionality such as security, network management, your cookie preferences and accessibility. Without them you wouldn't be able to use basic services. You may disable these by changing your browser settings, but this may affect how the Websites function.",
            "We use the following types of cookies in our platform:": "We use the following types of cookies in our platform:",
            "Strictly Necessary cookies are necessary for our platform to function and cannot be switched off in our systems. They are essential in order to enable you to navigate around the platform and use its features. If you remove or disable these cookies, we cannot guarantee that you will be able to use our platform.": "Strictly Necessary cookies are necessary for our platform to function and cannot be switched off in our systems. They are essential in order to enable you to navigate around the platform and use its features. If you remove or disable these cookies, we cannot guarantee that you will be able to use our platform.",
            "Cookies can either be session cookies or persistent cookies. A session cookie expires automatically when you close your browser. A persistent cookie will remain until it expires or you delete your cookies. Expiration dates are set in the cookies themselves; some may expire after a few minutes while others may expire after multiple years. Cookies placed by the website you’re visiting are called «first party cookies».": "Cookies can either be session cookies or persistent cookies. A session cookie expires automatically when you close your browser. A persistent cookie will remain until it expires or you delete your cookies. Expiration dates are set in the cookies themselves; some may expire after a few minutes while others may expire after multiple years. Cookies placed by the website you’re visiting are called «first party cookies».",
            "We use first party and/or third party cookies on our platform for various purposes such as:": "We use first party and/or third party cookies on our platform for various purposes such as:",
            "A cookie is a small text file that is stored on your computer or other internet connected device in order to identify your browser, provide analytics, remember information about you such as your language preference or login information. They're completely safe and can't be used to run programs or deliver viruses to your device.": "A cookie is a small text file that is stored on your computer or other internet connected device in order to identify your browser, provide analytics, remember information about you such as your language preference or login information. They're completely safe and can't be used to run programs or deliver viruses to your device.",
            "This Cookie Policy explains how IT and Design RS d.o.o. and its affiliates (collectively «ITDRS», «we», «us», and «ours»), use cookies and similar technologies to recognize you when you visit our platform, including without limitation https://itdrs.tech and any related URLs, mobile or localized versions and related domains / sub-domains («Websites»). It explains what these technologies are and why we use them, as well as the choices for how to control them.": "This Cookie Policy explains how IT and Design RS d.o.o. and its affiliates (collectively «ITDRS», «we», «us», and «ours»), use cookies and similar technologies to recognize you when you visit our platform, including without limitation https://itdrs.tech and any related URLs, mobile or localized versions and related domains / sub-domains («Websites»). It explains what these technologies are and why we use them, as well as the choices for how to control them.",
            "To help explain things as clearly as possible in this Cookie Policy, every time any of these terms are referenced, are strictly defined as:": "To help explain things as clearly as possible in this Cookie Policy, every time any of these terms are referenced, are strictly defined as:",
            "To facilitate the tailoring of online advertising to your interests.": "To facilitate the tailoring of online advertising to your interests.",
            "To identify future prospects and personalize marketing and sales interactions with it;": "To identify future prospects and personalize marketing and sales interactions with it;",
            "To analyze how our platform is used and how best we can customize it;": "To analyze how our platform is used and how best we can customize it;",
            "To allow us to make a bespoke user experience for you and for us to understand what is useful or of interest to you;": "To allow us to make a bespoke user experience for you and for us to understand what is useful or of interest to you;",
            "To improve your experience of our platform and make navigating around them quicker and easier;": "To improve your experience of our platform and make navigating around them quicker and easier;",
            "To facilitate the operation and functionality of our platform;": "To facilitate the operation and functionality of our platform;",
            "Cookie: ": "Cookie: ",
            "Company: ": "Company: ",
            "Device: ": "Device: ",
            "Personal Data: ": "Personal Data: ",
            "Service: ": "Service: ",
            "Third-party service: ": "Third-party service: ",
            "Website: ": "Website: ",
            "You: ": "You: ",
            " a person or entity that is registered with ITDRS to use the Services.": "a person or entity that is registered with ITDRS to use the Services.",
            " IT and Design RS d.o.o. site, which can be accessed via this URL": "IT and Design RS d.o.o. site, which can be accessed via this URL",
            " refers to advertisers, contest sponsors, promotional and marketing partners, and others who provide our content or whose products or services we think may interest you.": " refers to advertisers, contest sponsors, promotional and marketing partners, and others who provide our content or whose products or services we think may interest you.",
            " refers to the service provided by ITDRS as described in the relative terms (if available) and on this platform.": "refers to the service provided by ITDRS as described in the relative terms (if available) and on this platform.",
            " any information that directly, indirectly, or in connection with other information — including a personal identification number — allows for the identification or identifiability of a natural person.": "any information that directly, indirectly, or in connection with other information — including a personal identification number — allows for the identification or identifiability of a natural person.",
            " any internet connected device such as a phone, tablet, computer or any other device that can be used to visit ITDRS and use the services.": " any internet connected device such as a phone, tablet, computer or any other device that can be used to visit ITDRS and use the services.",
            " when this policy mentions “Company,” “we,” “us,” or “our,” it refers to ITDRS that is responsible for your information under this Cookie Policy.": "when this policy mentions “Company,” “we,” “us,” or “our,” it refers to ITDRS that is responsible for your information under this Cookie Policy.",
            " small amount of data generated by a website and saved by your web browser. It is used to identify your browser, provide analytics, remember information about you such as your language preference or login information.": "small amount of data generated by a website and saved by your web browser. It is used to identify your browser, provide analytics, remember information about you such as your language preference or login information.",
        }
    },
    sr: {
        translation: {
            "E-mail": "E-mail",
            "Accept": "Prihvatiti",
            "Clients": "Klijenti",
            "Services": "Usluge",
            "Comment": "Komentar",
            "Projects": "Projekti",
            "Book a call": "Zakažite poziv",
            "Learn more": "Saznati više",
            "We offer": "Nudimo",
            "Updated at ": "Ažurirano u ",
            "By using our site, you agree to our ": "Korištenjem naše stranice slažete se s našim ",
            "full development cycle": "pun razvojni ciklus",
            "from design to testing": " od dizajna do testiranja",
            "or we are ready to join your project at any stage": "ili smo spremni da se pridružimo vašem projektu u bilo kojoj fazi",
            "from design to testing or we are ready to join your project at any stage": "od dizajna do testiranja ili smo spremni da se pridružimo vašem projektu u bilo kojoj fazi",
            "Architecture": "Arhitektura",
            "Our team can provide you with a comprehensive software project architecture development service that will help you achieve your goals.": "Naš tim vam može pružiti sveobuhvatnu softversku projektnu razvojnu uslugu koja će vam pomoći da postignete svoje ciljeve.",
            "Design": "Dizajn",
            "Our team provides comprehensive web design services to create visually stunning, user-friendly websites that align with your business goals and target audience.": "Naš tim pruža sveobuhvatne usluge web dizajna za kreiranje vizuelno zadivljujućih i prilagođenih korisniku web sajtove koji su u skladu sa vašim poslovnim ciljevima i ciljnim grupama.",
            "Development": "Razvoj",
            "Infrastructure": "Infrastruktura",
            "We use VDS or cloud providers like AWS, Google Cloud, Yandex Cloud, and Selectel to develop our&nbsp;solutions.": "Mi koristimo VDS ili cloud provajdere kao što su AWS, Google Cloud, Yandex Cloud i Selectel da razvijamo naša rešenja.",
            "Frontend": "Frontend",
            "We use the latest technologies like Vanilla JS, React, and&nbsp;Server-Side Rendering (SSR) to develop fast, responsive and&nbsp;dynamic frontend solutions.":"Koristimo najnovije tehnologije kao što su Vanilla JS, React i Server-Side Rendering (SSR) za razvoj brzih, odgovornih i dinamičnih frontend rešenja.",
            "Backend":"Backend",
            "Whether you need a monolithic or microservices architecture, our team can help you make the right choice for your project.":"Bilo da vam je potrebna monolitna ili mikroservisna arhitektura, naš tim vam može pomoći da napravite pravi izbor za vaš projekat.",
            "Testing": "Testiranje",
            "Our team performs thorough testing to ensure that the product meets the requirements and works as expected.\n Don't let lack of in-house development expertise hold you back. Let us build your custom software today.": "Naš tim vrši temeljno testiranje kako bi se uverio da proizvod ispunjava zahteve i da radi kako se očekuje. Ne dozvolite da nedostatak stručnosti iz in-house developmenta sputava. Dozvolitete nam da vam napravimo prilagođeni softver već danas.",
            "Build my solution": "Napravite moje rešenje",
            "You have a super 100% fantastic idea of a product you want, but do not know what you need to implement it? Should it be a standalone program or a cloud solution? We will listen to your need and offer your most suitable solution from which blocks it should be build.": "Imate super 100% fantastičnu ideju za proizvod koji želite ali ne znate šta vam je potrebno za implementaciju? Da li bi trebalo da bude samostalni program ili cloud rešenje?",
            "Message us to get an architectural and product development advice.": "Pošaljite nam poruku da dobijete savet za arhitekturu i razvoj proizvoda.",
            "You have a super 100% fantastic idea of a product you want, but do not know what you need to implement it? Should it be a standalone program or a cloud solution?": "Imate super 100% fantastičnu ideju za proizvod koji želite ali ne znate šta vam je potrebno za implementaciju? Da li bi trebalo da bude samostalni program ili cloud rešenje?",
            "We will listen to your need and offer your most suitable solution from which blocks it should be build. Message us to get an architectural and product development advice.": " Saslušaćemo vaše potrebe i ponuditi vam najpogodnije rešenje od kojih blokova treba da bude izrađen. Pošaljite nam poruku da dobijete savet za arhitekturu i razvoj proizvoda.",
            "You have several systems which need to exchange information and work together? We can investigate and offer options how to deal with it, including adapters, API bridges, etc.":"Imate nekoliko sistema koji treba da razmenjuju informacije i rade zajedno? Možemo da istražimo i ponudimo opcije kako da se reše, uključujući adapere, API mostove, itd.",
            "Integration": "Integracija",
            "Message us to get an intergration advice.":"Pošaljite nam poruku i dobićete savet za integraciju.",
            "You have a software your company works on, but the deadline is near and there is still much to be done?": "Imate softver na kojem vaša kompanija radi ali rok je blizu i ima još mnogo toga da se uradi?",
            "Lets see how the scope can be optimized and which resources you need to finish your tasks in time. Message us to get development advice.": "Hajde da vidimo kako se obim posla može optimizovati i koji resursi su vam potrebni da bi ste svoje zadatke završili na vreme. Pošaljite nam poruku da dobijemo savjet za razvoj.",
            "Message us to get development advice.": "Pošaljite nam poruku da dobijete savet za razvoj.",
            "New technologies": "Nove tehnologije",
            "You have a running business and you see how you can get an edge over your competitors, but you need an instrument for it. We can help you get a leverage with new technology.": "Imate posao koji vodite i vidite kako možete da budete ispred konkurencije ali vam je potreban instrument za to. Možemo vam pomoći da steknete tu prednost uz pomoć  nove tehnologije.",
            "Message us to get competitive advantage with technological solutions.": "Pošaljite nam poruku da dobijete prednost u odnosu na konkurenciju sa tehnološkim rešenjima.",
            "Send": "Pošalji",
            "Message": "Poruka",
            "Thank you.": "Hvala puno!",
            "We shall contact you shortly! ITDRS team": "Kontaktiraćemo vas uskoro! ITDRS tim",
            "Contacts": "Kontakt",
            "Belgrade": "Beograd",
            "Cookie Policy": "Politika Cookie",
            "Contact us": "Kontaktirajte nas",
            "Leave your contact details and we will get back to you as soon as possible": "Ostavite svoj detalj za kontakt i javit ćemo vam se što je prije moguće",
            "Write your e-mail address": "Napišite svoju e-mail adresu",
            "Write your message": "Napišite svoju poruku",
            "I agree to transmit data for feedback": "Slažem se prenijeti podatke za povratne informacije\n",
            "Or we are ready to join your project at any stage of development": "Ili smo spremni pridružiti se vašem projektu u bilo kojoj fazi razvoja",
            "You have a running business and you see how you can get an edge over your competitors, but you need an instrument for it.": "Imate posao i vidite kako možete dobiti prednost nad svojim konkurentima, ali za to vam treba instrument.\n",
            "We can help you get a leverage with new technology. Message us to get competitive advantage with technological solutions.": "Možemo vam pomoći da dobijete utjecaj s novom tehnologijom. Pošaljite nam poruku da dobijemo konkurentsku prednost s tehnološkim rješenjima.\n",
            "You have several systems which need to exchange information and work together?": "Imate nekoliko sustava koji trebaju razmjenjivati ​​informacije i raditi zajedno?",
            "We can investigate and offer options how to deal with it, including adapters, API bridges, etc. Message us to get an intergration advice.": "Možemo istražiti i ponuditi opcije kako se nositi s tim, uključujući adaptere, mostove API -ja itd. Pošaljite nam poruku da dobijemo savjet o intergraciji.\n",
            "An unconventional task?": "Nekonvencionalan zadatak?",
            "Contact us. We will look at your task and propose a solution": "Kontaktirajte nas. Pogledat ćemo vaš zadatak i predložiti rješenje",
            "Virtua Gym system & the Holded accounting system": "Virtua Gym sustav i Hold računovodstveni sustav",
            "Our team recently developed an adapter between the Virtua Gym system and the Holded accounting system. This adapter seamlessly forwards sales and client information from the VirtuaGym to Holded, ensuring that all data is accurate and up-to-date. This solution allows for streamlined data management and improved financial reporting capabilities for our client. By implementing this adapter, our client has been able to save time and resources previously spent on manual data transfer.": "Naš tim nedavno je razvio adapter između sustava Virtua Gym i sustava Holded accounting. Ovaj adapter neprimjetno prosljeđuje podatke o prodaji i klijentima s VirtuaGyma na Holded, osiguravajući da su svi podaci točni i ažurni. Ovo rješenje omogućuje pojednostavljeno upravljanje podacima i poboljšane mogućnosti financijskog izvješćivanja za našeg klijenta. Implementacijom ovog adaptera, naš klijent je uspio uštedjeti vrijeme i resurse koje je prethodno trošio na ručni prijenos podataka.",
            "Finance": "Financije",
            "Accounting": "Računovodstvo",
            "Adapters": "Adapteri",
            "Signatures": "Potpisi",
            "Data": "Podaci",
            "Benefits": "Prednosti",
            "Key Features": "Ključne značajke",
            "E-signature systems": "Sustavi e-potpisa",
            "Billing system": "Sustav naplate",
            "Clients personal space": "Osobni prostor klijenata",
            "Streamlining Sales Data Management and Incentive Programs": "Pojednostavljenje upravljanja podacima o prodaji i programi poticaja",
            "Your team has experience in integrating with electronic signature systems to provide services for exchanging documents signed with qualified encrypted signatures. This integration allows for secure and legally binding digital document signing, streamlining the document exchange process and increasing efficiency for our clients. The integration ensures compliance with relevant laws and regulations, making it ideal for industries such as finance, healthcare and legal.": "Vaš tim ima iskustva u integraciji sa sustavima elektroničkog potpisa za pružanje usluga za razmjenu dokumenata potpisanih kvalificiranim šifriranim potpisima. Ova integracija omogućuje sigurno i pravno obvezujuće digitalno potpisivanje dokumenata, pojednostavljuje proces razmjene dokumenata i povećava učinkovitost za naše klijente. Integracija osigurava usklađenost s relevantnim zakonima i propisima, što je čini idealnom za industrije kao što su financije, zdravstvo i pravo.",
            "A client which is rendering IT services had its own billing service and was looking for a solution to integrate bank account information with the billing system. He did not want to use any SaaS solution because data protection was critical. We developed an on-premise scalable solution, which supports both manual and automated data input and integrated it with a given clients billing systems.": "Klijent koji pruža IT usluge imao je vlastitu uslugu naplate i tražio je rješenje za integraciju podataka o bankovnom računu sa sustavom naplate. Nije želio koristiti nikakvo SaaS rješenje jer je zaštita podataka bila kritična. Razvili smo lokalno skalabilno rješenje koje podržava i ručni i automatski unos podataka i integrirali ga sa sustavima naplate zadanih klijenata.",
            "A client had a CRM system, where he had his leads and clients registered, and a separate website. We implemented a clients personal space where they could login and see their data on visits and performance streamlined from CRM system.": "Klijent je imao CRM sustav, gdje je imao registrirane potencijalne klijente i klijente, te zasebno web mjesto. Implementirali smo osobni prostor klijenata gdje su se mogli prijaviti i vidjeti svoje podatke o posjetima i učinku usmjerene iz CRM sustava.",
            "We have developed a cutting-edge back-office system designed to revolutionize how businesses handle sales data and incentivize their sales teams. Our platform seamlessly collects and organizes sales reports from sales representatives and distributors, automates bonus point calculations, and manages engaging raffle programs-all through an intuitive admin panel.": "Razvili smo vrhunski sustav pozadinskih ureda dizajniran da revolucionira način na koji tvrtke obrađuju podatke o prodaji i potakne svoje prodajne timove. Naša platforma besprijekorno prikuplja i organizira izvješća o prodaji od prodajnih predstavnika i distributera, automatizira izračune bonus bodova i upravlja zanimljivim programima nagradne igre - sve putem intuitivne administrativne ploče.",
            "Automated Data Collection & Organization": "Automatizirano prikupljanje i organizacija podataka",
            "Dynamic Bonus Point Calculation": "Dinamički izračun bonus bodova",
            "Engaging Raffle System": "Zanimljiv sustav nagradne igre",
            "Intuitive Admin Panel": "Intuitivna administrativna ploča",
            "Comprehensive Reporting & Analytics": "Sveobuhvatno izvješćivanje i analiza",
            "Boosted Team Motivation": "Pojačana motivacija tima",
            "Increased Efficiency": "Povećana učinkovitost",
            "Customization & Scalability": "Prilagodba i skalabilnost",
            "Fair & Transparent Rewards": "Poštene i transparentne nagrade",
            "Ensure all sales representatives have opportunities to be rewarded, with higher achievers receiving greater chances in raffles, promoting fairness and enthusiasm.": "Pobrinite se da svi prodajni predstavnici imaju priliku biti nagrađeni, pri čemu oni s boljim rezultatima imaju veće šanse u nagradnoj igri, promičući poštenje i entuzijazam.",
            "Tailor the system to fit your unique business needs with the flexibility to scale as your company grows.": "Prilagodite sustav kako bi odgovarao vašim jedinstvenim poslovnim potrebama uz fleksibilnost za skaliranje kako vaša tvrtka raste.",
            "Reduce administrative workload with automated processes, allowing your team to focus on strategic tasks rather than manual data management.": "Smanjite administrativno opterećenje automatiziranim procesima, omogućujući vašem timu da se usredotoči na strateške zadatke umjesto na ručno upravljanje podacima.",
            "Implement a transparent and rewarding system that recognizes and incentivizes top performers, fostering a competitive yet collaborative environment.": "Implementirajte transparentan i nagrađivan sustav koji prepoznaje i potiče vrhunske izvođače, potičući konkurentno, ali suradničko okruženje.",
            "Comprehensive Reporting & Analytics: Access detailed reports and analytics to track sales trends, bonus distributions, and the effectiveness of incentive programs, enabling informed decision-making.": "Sveobuhvatno izvješćivanje i analitika: pristupite detaljnim izvješćima i analitici za praćenje prodajnih trendova, raspodjele bonusa i učinkovitosti poticajnih programa, omogućujući informirano donošenje odluka.",
            "Gain full control over settings, rules, and parameters. Easily adjust bonus calculations, manage raffle criteria, and monitor sales performance through a user-friendly interface.": "Steknite potpunu kontrolu nad postavkama, pravilima i parametrima. Jednostavno prilagodite izračune bonusa, upravljajte kriterijima nagradne igre i pratite prodajne rezultate putem korisničkog sučelja.",
            "On a periodic basis (e.g., monthly), the system conducts a raffle to award promotional gifts to your sales representatives. Utilizing a sophisticated algorithm with random generators, the raffle is weighted according to the bonus points earned, enhancing engagement and excitement among your team.": "Povremeno (npr. mjesečno), sustav provodi izvlačenje za dodjelu promotivnih darova vašim prodajnim predstavnicima. Koristeći sofisticirani algoritam s nasumičnim generatorima, tombola se ponderira prema zarađenim bonus bodovima, povećavajući angažman i uzbuđenje među vašim timom.",
            "Customize bonus point rules and settings via the admin panel. The system automatically calculates points based on these parameters, ensuring fair and motivating rewards aligned with your business goals.": "Prilagodite pravila i postavke bonus bodova putem administratorske ploče. Sustav automatski izračunava bodove na temelju tih parametara, osiguravajući pravedne i motivirajuće nagrade usklađene s vašim poslovnim ciljevima.",
            "Effortlessly gather sales data from various sources. Our system consolidates sales reports from representatives and distributors into a centralized database, eliminating manual entry and reducing errors.": "Bez napora prikupite podatke o prodaji iz različitih izvora. Naš sustav konsolidira izvješća o prodaji od predstavnika i distributera u centraliziranu bazu podataka, eliminirajući ručni unos i smanjujući pogreške.",
            "2024 All rights reserved": "2024 Sva prava pridržana",
            "Definitions And Key Terms": "Definicije i ključni pojmovi",
            "Introduction": "Uvod",
            "What Is  A Cookie?": "Što je Cookie?",
            "Why Do We Use Cookies?": "Zašto koristimo Cookie?",
            "What Type Of Cookies  Does ITDRS Use?": "Koju vrstu Cookies koristi ITDRS?",
            "Essential Cookies": "Osnovni Cookies",
            "Performance  And Functionality Cookies": "Cookies za izvedbu i funkcionalnost",
            "Analytics  And Customization Cookies": "Cookies za analitiku i prilagodbu",
            "Third Party Cookies": "Cookies treće strane",
            "How You Can Manage Cookies?": "Kako možete upravljati Cookies?",
            "Blocking And Disabling  Cookies And Similar  Technologies": "Blokiranje i onemogućavanje Cookies i sličnih tehnologija",
            "Changes To Our  Cookie Policy": "Promjene naše politike o Cookie",
            "Your Consent": "Vaš pristanak",
            "By using our platform, registering an account, or making a purchase, you hereby consent to our Cookie Policy and agree to its terms.": "Korištenjem naše platforme, registracijom računa ili kupnjom, ovime pristajete na našu Politiku Cookie i slažete se s njezinim uvjetima.",
            "We may change our Service and policies, and we may need to make changes to this Cookie Policy so that they accurately reflect our Service and policies. Unless otherwise required by law, we will notify you (for example, through our Service) before we make changes to this Cookie Policy and give you an opportunity to review them before they go into effect. Then, if you continue to use the Service, you will be bound by the updated Cookie Policy. If you do not want to agree to this or any updated Cookie Policy, you can delete your account.": "Možemo promijeniti našu uslugu i pravila, a možda ćemo morati unijeti i izmjene u ova pravila o Cookie tako da točno odražavaju našu uslugu i pravila. Osim ako nije drugačije propisano zakonom, obavijestit ćemo vas (na primjer, putem naše usluge) prije nego što unesemo izmjene u ovu Politiku Cookie i dati vam priliku da ih pregledate prije nego što stupe na snagu. Zatim, ako nastavite koristiti uslugu, bit ćete vezani ažuriranom Politikom Cookie. Ako se ne želite složiti s ovom ili bilo kojom ažuriranom Politikom Cookie, možete izbrisati svoj račun.",
            "Wherever you're located you may also set your browser to block cookies and similar technologies, but this action may block our essential cookies and prevent our website from functioning properly, and you may not be able to fully utilize all of its features and services. You should also be aware that you may also lose some saved information (e.g. saved login details, site preferences) if you block cookies on your browser. Different browsers make different controls available to you. Disabling a cookie or category of cookie does not delete the cookie from your browser, you will need to do this yourself from within your browser, you should visit your browser's help menu for more information.": "Gdje god se nalazili, također možete postaviti svoj preglednik da blokira cookies i slične tehnologije, ali ova radnja može blokirati naše bitne cookies i spriječiti ispravno funkcioniranje naše web stranice, a možda nećete moći u potpunosti koristiti sve njegove značajke i usluge. Također biste trebali biti svjesni da možete izgubiti i neke spremljene informacije (npr. spremljene podatke za prijavu, postavke web-mjesta) ako blokirate cookies u svom pregledniku. Različiti preglednici omogućuju vam različite kontrole. Onemogućavanje Cookie ili kategorije Cookie ne briše kolačić iz vašeg preglednika, morat ćete to učiniti sami iz svog preglednika, trebali biste posjetiti izbornik za pomoć vašeg preglednika za više informacija.",
            "Browser manufacturers provide help pages relating to cookie management in their products.": "Proizvođači preglednika pružaju stranice pomoći koje se odnose na upravljanje Cookie u njihovim proizvodima.",
            "Most browsers allow you to control cookies through their 'settings' preferences. However, if you limit the ability of websites to set cookies, you may worsen your overall user experience, since it will no longer be personalized to you. It may also stop you from saving customized settings like login information. Browser manufacturers provide help pages relating to cookie management in their products.": "Većina preglednika dopušta vam kontrolu Cookie putem njihovih postavki 'postavki'. Međutim, ako ograničite mogućnost web stranica da postavljaju cookies, možete pogoršati svoje cjelokupno korisničko iskustvo jer ono više neće biti prilagođeno vama. Također vas može spriječiti u spremanju prilagođenih postavki poput podataka za prijavu. Proizvođači preglednika pružaju stranice pomoći koje se odnose na upravljanje Cookie u njihovim proizvodima.",
            "Some cookies that have been set on our platform are not set on a first party basis by ITDRS. The Websites can be embedded with content from third parties to serve advertising. These third party service providers may set their own cookies on your web browser. Third party service providers control many of the performance and functionality, advertising, marketing and analytics cookies described above. We do not control the use of these third party cookies as cookies can only be accessed by the third party that originally set them.": "Neke cookies koji su postavljeni na našoj platformi ITDRS ne postavlja na temelju prve strane. Web stranice mogu biti ugrađene sa sadržajem trećih strana za posluživanje oglašavanja. Ovi pružatelji usluga treće strane mogu postaviti vlastite cookies na vaš web preglednik. Pružatelji usluga treće strane kontroliraju mnoge gore opisane cookies za izvedbu i funkcionalnost, oglašavanje, marketing i analitiku. Ne kontroliramo korištenje ovih Cookie trećih strana jer Cookie može pristupiti samo treća strana koja ih je izvorno postavila.",
            "We use cookies served by Google Analytics to collect limited data directly from end-user browsers to enable us to better understand your use of our platform. Further information on how Google collects and uses this data can be found at: https://www.google.com/policies/privacy/partners/. You can opt-out of all Google supported analytics on our Websites by visiting: https://tools.google.com/dlpage/gaoptout.": "Koristimo cookies koje poslužuje Google Analytics za prikupljanje ograničenih podataka izravno iz preglednika krajnjih korisnika kako bismo bolje razumjeli vašu upotrebu naše platforme. Dodatne informacije o tome kako Google prikuplja i koristi ove podatke možete pronaći na: https://www.google.com/policies/privacy/partners/. Možete se isključiti iz svih analitičkih podataka koje Google podržava na našim web stranicama tako da posjetite: https://tools.google.com/dlpage/gaoptout.",
            "These cookies collect information that is used to help us understand how our platform is being used or how effective our marketing campaigns are, or to help us customize our platform for you.": "Ovi Cookie prikupljaju informacije koje nam pomažu razumjeti kako se naša platforma koristi ili koliko su učinkovite naše marketinške kampanje, ili da nam pomognu da prilagodimo našu platformu vama.",
            "These cookies are used to enhance the performance and functionality of our platform but are non-essential to their use. However, without these cookies, certain functionality like videos may become unavailable or you would be required to enter your login details every time you visit the platform as we would not be able to remember that you had logged in previously.": "Ovi se Cookie koriste za poboljšanje performansi i funkcionalnosti naše platforme, ali nisu bitni za njihovu upotrebu. Međutim, bez ovih Cookie, određene funkcije poput videozapisa mogu postati nedostupne ili biste morali unijeti svoje podatke za prijavu svaki put kada posjetite platformu jer se ne bismo mogli sjetiti da ste se prethodno prijavili.",
            "We use essential cookies to make our platform work. These cookies are strictly necessary to enable core functionality such as security, network management, your cookie preferences and accessibility. Without them you wouldn't be able to use basic services. You may disable these by changing your browser settings, but this may affect how the Websites function.": "Koristimo bitne cookies kako bi naša platforma funkcionirala. Ovi Cookies su strogo potrebni za omogućavanje temeljnih funkcija kao što su sigurnost, upravljanje mrežom, vaše postavke Cookie i pristupačnost. Bez njih ne biste mogli koristiti osnovne usluge. Možete ih onemogućiti promjenom postavki preglednika, ali to može utjecati na funkcioniranje web stranica.",
            "We use the following types of cookies in our platform:": "Na našoj platformi koristimo sljedeće vrste Cookie:",
            "Strictly Necessary cookies are necessary for our platform to function and cannot be switched off in our systems. They are essential in order to enable you to navigate around the platform and use its features. If you remove or disable these cookies, we cannot guarantee that you will be able to use our platform.": "Strogo nužni Cookie neophodni su za funkcioniranje naše platforme i ne mogu se isključiti u našim sustavima. Oni su neophodni kako bi vam omogućili navigaciju platformom i korištenje njezinih značajki. Ako uklonite ili onemogućite ove cookies, ne možemo jamčiti da ćete moći koristiti našu platformu.",
            "Cookies can either be session cookies or persistent cookies. A session cookie expires automatically when you close your browser. A persistent cookie will remain until it expires or you delete your cookies. Expiration dates are set in the cookies themselves; some may expire after a few minutes while others may expire after multiple years. Cookies placed by the website you’re visiting are called «first party cookies».": "Cookie mogu biti Cookie sesije ili trajni kolačići. Sesijski kolačić automatski istječe kada zatvorite preglednik. Trajni kolačić ostat će dok ne istekne ili dok ne izbrišete cookies. Datumi isteka postavljeni su u samim Cookie; neki mogu isteći nakon nekoliko minuta, dok drugi mogu isteći nakon više godina. Cookie koje postavlja web stranica koju posjećujete nazivaju se «Cookie prve strane».",
            "We use first party and/or third party cookies on our platform for various purposes such as:": "Na našoj platformi koristimo cookies prve strane i/ili treće strane u razne svrhe kao što su:",
            "A cookie is a small text file that is stored on your computer or other internet connected device in order to identify your browser, provide analytics, remember information about you such as your language preference or login information. They're completely safe and can't be used to run programs or deliver viruses to your device.": "Kolačić je mala tekstualna datoteka koja se pohranjuje na vašem računalu ili drugom uređaju povezanom s internetom kako bi identificirala vaš preglednik, pružila analitiku, zapamtila informacije o vama kao što su vaše postavke jezika ili podaci za prijavu. Potpuno su sigurni i ne mogu se koristiti za pokretanje programa ili isporuku virusa na vaš uređaj.",
            "This Cookie Policy explains how IT and Design RS d.o.o. and its affiliates (collectively «ITDRS», «we», «us», and «ours»), use cookies and similar technologies to recognize you when you visit our platform, including without limitation https://itdrs.tech and any related URLs, mobile or localized versions and related domains / sub-domains («Websites»). It explains what these technologies are and why we use them, as well as the choices for how to control them.": "Ova Pravila o Cookie objašnjavaju kako IT and Design RS d.o.o. i njegove podružnice (zajedničkim nazivom «ITDRS», «mi», «nas» ​​i «naš»), koriste cookies i slične tehnologije kako bi vas prepoznali kada posjetite našu platformu, uključujući bez ograničenja https://itdrs.tech i sve povezane URL-ovi, mobilne ili lokalizirane verzije i srodne domene/poddomene («web stranice»). Objašnjava što su te tehnologije i zašto ih koristimo, kao i izbore kako ih kontrolirati.",
            "To help explain things as clearly as possible in this Cookie Policy, every time any of these terms are referenced, are strictly defined as:": "Kako bismo što jasnije objasnili stvari u ovoj Politici Cookie, svaki put kad se spomene bilo koji od ovih pojmova, oni su strogo definirani kao:",
            "To facilitate the tailoring of online advertising to your interests.": "Kako bismo olakšali prilagođavanje online oglašavanja vašim interesima.",
            "To identify future prospects and personalize marketing and sales interactions with it;": "Identificirati buduće izglede i personalizirati marketinške i prodajne interakcije s njim;",
            "To analyze how our platform is used and how best we can customize it;": "Da analiziramo kako se naša platforma koristi i kako je najbolje možemo prilagoditi;",
            "To allow us to make a bespoke user experience for you and for us to understand what is useful or of interest to you;": "Da biste nam omogućili da za vas napravimo prilagođeno korisničko iskustvo i da bismo razumjeli što vam je korisno ili što vas zanima;",
            "To improve your experience of our platform and make navigating around them quicker and easier;": "Kako bismo poboljšali vaše iskustvo naše platforme i učinili navigaciju oko njih bržom i lakšom;",
            "To facilitate the operation and functionality of our platform;": "Kako bismo olakšali rad i funkcionalnost naše platforme;",
            "Cookie: ": "Cookie: ",
            "Company: ": "Tvrtka: ",
            "Device: ": "Uređaj: ",
            "Personal Data: ": "Osobni podaci: ",
            "Service: ": "Servis: ",
            "Third-party service: ": "Usluga treće strane: ",
            "Website: ": "Web stranica: ",
            "You: ": "Vas: ",
            " a person or entity that is registered with ITDRS to use the Services.": "osoba ili subjekt koji je registriran kod ITDRS-a za korištenje Usluga.",
            " IT and Design RS d.o.o. site, which can be accessed via this URL": "IT i dizajn RS d.o.o. web mjesto, kojem se može pristupiti putem ovog URL-a",
            " refers to advertisers, contest sponsors, promotional and marketing partners, and others who provide our content or whose products or services we think may interest you.": "odnosi se na oglašivače, sponzore natjecanja, promotivne i marketinške partnere i druge koji pružaju naš sadržaj ili za čije proizvode ili usluge smatramo da bi vas mogli zanimati.",
            " refers to the service provided by ITDRS as described in the relative terms (if available) and on this platform.": "odnosi se na uslugu koju pruža ITDRS kako je opisano u relativnim uvjetima (ako su dostupni) i na ovoj platformi.",
            " any information that directly, indirectly, or in connection with other information — including a personal identification number — allows for the identification or identifiability of a natural person.": "sve informacije koje izravno, neizravno ili u vezi s drugim informacijama — uključujući osobni identifikacijski broj — omogućuju identifikaciju ili prepoznatljivost fizičke osobe.",
            " any internet connected device such as a phone, tablet, computer or any other device that can be used to visit ITDRS and use the services.": " bilo koji uređaj povezan s internetom kao što je telefon, tablet, računalo ili bilo koji drugi uređaj koji se može koristiti za posjet ITDRS-u i korištenje usluga.",
            " when this policy mentions “Company,” “we,” “us,” or “our,” it refers to ITDRS that is responsible for your information under this Cookie Policy.": "kada se u ovoj politici spominje \"Tvrtka\", \"mi\", \"nas\" ili \"naš\", to se odnosi na ITDRS koji je odgovoran za vaše podatke prema ovim Pravilima o Cookies.",
            " small amount of data generated by a website and saved by your web browser. It is used to identify your browser, provide analytics, remember information about you such as your language preference or login information.": "mala količina podataka koje generira web stranica i sprema vaš web preglednik. Koristi se za identifikaciju vašeg preglednika, pružanje analitike, pamćenje informacija o vama kao što su vaše postavke jezika ili podaci za prijavu.",
        }
    }
};

i18n
    .use(initReactI18next) //Clients personal space passes i18n down to react-i18next
    .init({
        resources,
        fallbackLng: 'en',
        lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
