import {useTranslation} from "react-i18next";
import "./CookieCard.css";

const CookieCard = ({
    sup,
    list,
    title,
    subDescr,
    isDefList,
    thirdDescr,
    description,
    isDarkTheme,
}) => {
    const { t } = useTranslation();

    const renderListItem = list?.map((item) => {
        const itemSplit = item?.split(':');
        const itemHead = itemSplit?.[0];
        const itemDescr = itemSplit?.[1];

        return (
            <li>
                {itemHead && itemDescr && <b>{t(`${itemHead}: `)}</b>}
                {itemHead && !itemDescr && t(`${itemHead}`)}
                {itemDescr && t(`${itemDescr}`)}
            </li>
        )
    });

    return (
        <div key={sup} className={`cookie-card ${!isDarkTheme && 'cookie-card-light'}`}>
            <h2 className={`cookie-card-header ${!isDarkTheme && 'cookie-card-header-light'}`}>
                {`${sup}. ${t(title)}`}
            </h2>
            <p className={`cookie-card-descr ${!isDarkTheme && 'cookie-card-descr-light'}`}>
                {t(description)}
            </p>
            {subDescr && (
                <p className={`cookie-card-descr ${!isDarkTheme && 'cookie-card-descr-light'} cookie-card-sub-descr`}>
                    {t(subDescr)}
                </p>
            )}
            {thirdDescr && (
                <p className={`cookie-card-descr ${!isDarkTheme && 'cookie-card-descr-light'} cookie-card-sub-descr`}>
                    {t(thirdDescr)}
                </p>
            )}
            {!!list?.length && (
                <ul
                    className={`
                        cookie-card-list
                        cookie-card-descr 
                        ${!isDarkTheme && 'cookie-card-descr-light'}
                        ${isDefList && 'cookie-card-sub-descr'}
                    `}>
                    {renderListItem}
                </ul>
            )}
        </div>
    )
}

export default CookieCard;
