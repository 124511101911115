import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import logoWhite from "../../assets/img/logo_white.svg";
import logoBlack from "../../assets/img/logo_black.svg"
import blackMoon from "../../assets/img/icons/black_moon.svg";
import whiteSun from "../../assets/img/icons/white_sun.svg";
import Button from "../../components/Button/Button";
import ModalContactUs from "../../components/ModalContactUs/ModalContactUs";
import "./Header.css";

const Header = ({
    showModal = false,
    toggleTheme,
    setShowModal = () => {},
    inverseColors = false,
    isMobileHeader = false,
}) => {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const { i18n} = useTranslation();

    const activeLang = !inverseColors ? "active-base" : "active-white";
    const notActiveLang = inverseColors ? "not-active-base" : "not-active-white";
    const lang = i18n.language === 'en' ? activeLang : notActiveLang;


    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const isSr = document.cookie.split(';').includes(' lang=sr') || document.cookie.split(';').includes('lang=sr');

        i18n.changeLanguage(isSr ? 'sr' : 'en');
    }, [i18n]);

    useEffect(() => {
        const isLight = document.cookie.split(';').includes(' is_light=true') || document.cookie.split(';').includes('is_light=true');;

        return toggleTheme(isLight);
    }, [i18n]);

    const changeLanguage = (lng) => {
        document.cookie = `lang=${lng}; path=/; max-age=86400`;

        i18n.changeLanguage(lng);
    };

    const changeTheme = () => {
        document.cookie = `is_light=${inverseColors}; path=/; max-age=86400`;

        return toggleTheme(inverseColors);
    };

    const openModal = () => {
        setShowModal(!showModal);
    };

    const renderLinksButtons = () => {
        return (
            <div className='projects-links-button'>
                <div className={`language-selector ${inverseColors ? 'black-text' : ''}`}>
                    <Link
                        className={`
                            language-wrap 
                            ${lang}
                            ${inverseColors ? 'theme-toggle' : 'theme-toggle-black'}
                         `}
                        onClick={() => changeLanguage('en')}
                    >
                        EN
                    </Link>
                    <span className={notActiveLang}>/</span>
                    <Link
                        className={`
                            language-wrap 
                            ${i18n.language === 'sr' ? activeLang : notActiveLang}
                            ${inverseColors ? 'theme-toggle' : 'theme-toggle-black'}
                        `}
                        onClick={() => changeLanguage('sr')}
                    >
                        SR
                    </Link>
                </div>
                <div
                    className='language-selector theme-toggle-icon'
                    onClick={changeTheme}
                >
                    <img
                        src={!inverseColors ? blackMoon : whiteSun}
                        className={inverseColors ? 'theme-toggle' : 'theme-toggle-black'}
                        alt="Theme toggle"/>
                </div>
            </div>
        )
    };

    return (isMobileHeader ? (
            <header className="header">
                <Link style={{zIndex: 1}} to="/">
                    <img className="logo" src={inverseColors ? logoBlack : logoWhite} alt="IT and Design"/>
                </Link>
            </header>
        ) : (
            <header className="header">
                <Link style={{zIndex: 1}} to="/">
                    <img className="logo" src={inverseColors ? logoBlack : logoWhite} alt="IT and Design"/>
                </Link>
                <div className="buttons-block">
                    {screenWidth > 483 && renderLinksButtons()}
                    <Button onClick={openModal} inverseColors={inverseColors}/>
                </div>
                {screenWidth <= 483 && renderLinksButtons()}
                {showModal && (
                    <ModalContactUs
                        showModal={showModal}
                        setShowModal={setShowModal}
                        inverseColors={inverseColors}
                    />
                )}
            </header>
        )
    )
}

export default Header;
